import { UserDataRequest } from '@amedia/user';

import { type UserAccess } from './accessStatusResolver';
import { log } from './logging/logger';
import { fetchOfferings, type PublicationAccess } from './offerings';
import { getSiteEnv } from './utils/url';
import { getTestVariant } from './utils/fetchVariant';

// Prioritized order when getting redirect site
export enum Source {
  hasAccess = 'hasAccess',
  primarySite = 'primarySite',
  amedia_referrer = 'amedia_referrer',
  userData = 'userData',
  fallback = 'fallback',
}

type SiteResult = {
  source: Source;
  site: {
    src: string;
    target: string;
  };
};

const getSiteFromOffering = async (
  publicationDomain: string,
  publicationAccess: PublicationAccess,
  channel: string
) => {
  const { offerings } = await fetchOfferings(
    publicationDomain,
    publicationAccess,
    channel
  );
  return offerings.length > 0 ? offerings[0].product.publication_domain : null;
};

const isValidDomain = (val: string) => !!val.match(/www\.[^.]+\.no/);

const siteFromPrimarySite = async (
  publicationAccess: PublicationAccess,
  channel: string,
  user: UserAccess
): Promise<SiteResult | undefined> => {
  const domain = user.primarySite?.domain || '';
  const affilations = user.primarySite?.affiliations || [];

  if (!affilations.includes('default') && isValidDomain(domain)) {
    const site = await getSiteFromOffering(domain, publicationAccess, channel);

    if (site) {
      return {
        source: Source.primarySite,
        site: { src: domain, target: domain },
      };
    }
  }
  // primary site doesn't sell products with the required access features
};

const siteFromReferrer = async (
  publicationAccess: PublicationAccess,
  channel: string
): Promise<SiteResult | undefined> => {
  const params = new URLSearchParams(window.location.search);
  const referrer = params.get('amedia_referrer');

  if (referrer && isValidDomain(referrer)) {
    const site = await getSiteFromOffering(
      referrer,
      publicationAccess,
      channel
    );

    if (site) {
      return {
        source: Source.amedia_referrer,
        site: { src: referrer, target: site },
      };
    }
  }
  // referrer site doesn't sell products with the required access features
};

const siteFromUserData = async (
  publicationAccess: PublicationAccess,
  channel: string
): Promise<SiteResult | undefined> => {
  if (globalThis.appProps.isDev) {
    return undefined; //cant run amedia-user-js on localhost
  }
  const { attributes } = await new UserDataRequest()
    .withAttributes(['extraData'])
    .fetch();
  const altPrimarySite = attributes.extraData?.[
    'primary_plussalt_domain_visits'
  ] as string | undefined;

  if (altPrimarySite && isValidDomain(altPrimarySite)) {
    const site = await getSiteFromOffering(
      altPrimarySite,
      publicationAccess,
      channel
    );

    if (site) {
      return {
        source: Source.userData,
        site: { src: altPrimarySite, target: site },
      };
    }
  }
  // altPrimarySite doesn't sell products with the required access features
};

const getRedirectData = async (
  user: UserAccess,
  publicationAccess: PublicationAccess,
  channel: string
): Promise<SiteResult> => {
  const primarySiteResult = await siteFromPrimarySite(
    publicationAccess,
    channel,
    user
  );
  if (primarySiteResult) {
    return primarySiteResult;
  }

  const referrerResult = await siteFromReferrer(publicationAccess, channel);
  if (referrerResult) {
    return referrerResult;
  }

  const userDataResult = await siteFromUserData(publicationAccess, channel);
  if (userDataResult) {
    return userDataResult;
  }

  const fallbackSite = 'www.nettavisen.no';
  const fallback = {
    source: Source.fallback,
    site: { src: fallbackSite, target: fallbackSite },
  };
  return fallback;
};

export const getPurchaseUrl = async (
  user: UserAccess,
  missingAccessFeatures: string[],
  siteDomain: string,
  requestedUrl?: URL
) => {
  const channel =
    new URLSearchParams(window.location.search).get('channel') ||
    'product_overview';
  const publicationAccess = [
    {
      publicationDomain: siteDomain,
      accessFeatures: missingAccessFeatures,
    },
  ];
  const env = getSiteEnv(window.location.host);

  const [redirectData, abTestVariant] = await Promise.all([
    getRedirectData(user, publicationAccess, channel),
    getTestVariant('broiler_redirect_direktesport'),
  ]);

  const purchaseUrl = new URL(
    `${window.location.protocol}//${redirectData.site.target}${env}/tilbud/utvalgt`
  );
  purchaseUrl.searchParams.set(
    'access_features',
    missingAccessFeatures.join(',')
  );

  purchaseUrl.searchParams.set('site_domain', siteDomain);

  purchaseUrl.searchParams.set('channel', channel);

  if (siteDomain === 'www.direktesport.no') {
    purchaseUrl.searchParams.set('source', 'direktesport');

    if (abTestVariant.feature_enabled) {
      purchaseUrl.searchParams.set(
        'broiler_redirect_direktesport_group',
        abTestVariant.name
      );

      if (abTestVariant.name === 'test-group') {
        purchaseUrl.pathname = '/tilbud/direktesport';
      }
    }
  }

  if (siteDomain === 'www.alt.no') {
    purchaseUrl.searchParams.set('source', 'alt');
  }

  if (requestedUrl) {
    purchaseUrl.searchParams.set('requestedUrl', requestedUrl.href);
  }

  log.info(
    `used ${redirectData.source}(${redirectData.site.src}) to redirect to ${purchaseUrl.href}`
  );
  return { url: purchaseUrl, source: redirectData.source };
};
