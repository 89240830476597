import { mount } from 'svelte';

import App from './app.svelte';
import { log } from './logging/logger';

const body = document.querySelector('body');

if (body) {
  mount(App, {
    target: body,
    props: {
      isDev: globalThis.appProps.isDev,
      isDebug: globalThis.appProps.isDebug,
    },
  });
} else {
  log.error('No body element found when instantiating app');
}
