<script lang="ts">
  import { PaywallError } from '@amedia/user';
  import { onMount } from 'svelte';

  import { type AccessResolverResponse } from './accessStatusResolver';
  import Debug from './components/debug/debug.svelte';
  import DevControls from './components/devControls/devControls.svelte';
  import Error from './components/error/error.svelte';
  import Spinner from './components/spinner/spinner.svelte';
  import { mockUnlock } from './mockUnlock';
  import { unlock } from './unlock';
  import WR from './utils/webresponse';

  export let isDev = false;
  export let isDebug = false;

  let accessResponse = new Promise<AccessResolverResponse>(() => {});

  onMount(() => {
    accessResponse = isDev ? mockUnlock() : unlock();
  });

  const errors = {
    [PaywallError.ERROR_ENABLING_ACCESS_FAILED]: 'Enabling access failed',
    [PaywallError.ERROR_NO_ACCESS_AFTER_SUCCESSFUL_ACTIVATION_WITH_REQUESTED_ACCESS_FEATURES]:
      'No access after successful activation with requested access features',
    [PaywallError.ERROR_RELOADED_NO_ACCESS]: 'Reloaded no access',
  };
</script>

{#if isDev}
  <DevControls />
{/if}

<!-- App will redirect when accessResponse is resolved "debug" in query params will prevent redirect -->
{#await accessResponse}
  <Spinner>Sjekker tilgang</Spinner>
{:then response}
  {#if WR.isError(response)}
    <Error title="Mangler parametere" text={response.error.message} />
  {:else if isDebug}
    <Debug {response} />
  {/if}
{:catch error}
  <Error text={errors[error.code] || error} />
{/await}
