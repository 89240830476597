<script lang="ts">
  import { logout } from '@amedia/user';

  import { Source } from '../../getPurchaseUrl';

  import { type AccessResolverData } from 'src/accessStatusResolver';

  export let response: AccessResolverData;

  const logoutAndReload = () => logout().then(() => window.location.reload());

  const isActive = (a: Source, b: Source) => (a === b ? 'active' : '');
</script>

<div class="access-check">
  <h1>Debug Mode</h1>
  <div class="response">
    <h4>Respons fra paywallUnlocker:</h4>
    <div>
      isLoggedIn: <b>{response.user.isLoggedIn}</b>
    </div>
    <div>
      hasAccess: <b>{response.user.hasAccess}</b>
    </div>
    {#if response.user.hasAccess}
      <div class="info">
        <p>
          If user has access on current domain the paywallUnlocker doesn't
          bother to check for access on site_domain
        </p>
        <p>In that case, accessFeatures and primarySite is irrelevant</p>
      </div>
    {/if}
    <div>
      accessFeatures: <b>{response.user.accessFeatures}</b>
    </div>
    <div class="primary-site">
      <div>primarySite:</div>
      <ul>
        <li>name: <b>{response.user.primarySite?.name} </b></li>
        <li>domain: <b>{response.user.primarySite?.domain} </b></li>
        <li>
          affiliations: <b>{response.user.primarySite?.affiliations} </b>
        </li>
      </ul>
    </div>

    <div>
      <h4>Requested:</h4>
      <span>Checked for access features:</span>
      <b>{response.requestedAccess.accessFeatures}</b>
      <span>on domain:</span>
      <b>{response.requestedAccess.domain}</b>
    </div>
    <div>
      <span>On access, redirect to:</span>
      <b>{response.requestedAccess.url}</b>
    </div>

    {#if response.missingAccessFeatures}
      <h4>Missing access features:</h4>
      {response.missingAccessFeatures}
    {/if}

    <div class="redirect">
      Bruker vil bli sendt til
      <a href={response.purchaseUrlData.url.href}>
        {response.purchaseUrlData.url.href}
      </a>
      <p>Kilde:</p>
      <ol>
        {#each Object.values(Source) as source}
          <li class={isActive(source, response.purchaseUrlData.source)}>
            {source}
          </li>
        {/each}
      </ol>
    </div>
  </div>
  {#if response.user.isLoggedIn}
    <button on:click={logoutAndReload}>Logg ut</button>
  {/if}
</div>

<style>
  .access-check {
    width: 650px;
    margin: 0 auto;
    background: white;
    color: black;
    box-shadow: 1px 1px 8px #ccc;
    font-family: Arial, Helvetica, sans-serif;
    padding: 20px;
  }

  .access-check h4 {
    margin-bottom: 4px;
  }

  .primary-site ul {
    margin-top: 0;
    list-style: none;
    padding-left: 10px;
  }

  .redirect {
    margin: 20px 0 20px;
    background: #befec5;
    padding: 10px;
    border-radius: 10px;
  }
  .redirect a {
    display: block;
    overflow-wrap: break-word;
  }

  .redirect ol {
    list-style-position: inside;
    padding: 0;
  }

  .redirect ol li.active {
    font-weight: bold;
  }

  .info p {
    font-family: monospace;
    color: tomato;
  }
</style>
