import { goToLoginPage, SiteAccessRequest } from '@amedia/user';

import {
  type AccessResolverResponse,
  getRequested,
  resolveAccess,
} from './accessStatusResolver';
import { log } from './logging/logger';
const requested = getRequested();

export const unlock = async (): Promise<AccessResolverResponse> => {
  if (requested.error) {
    return {
      error: requested.error,
    };
  }
  try {
    const { isLoggedIn, hasAccess, accessFeatures, primarySite } =
      await new SiteAccessRequest(requested.accessFeatures)
        .withSiteDomain(requested.domain)
        .requestAccess();

    const resolverResponse = await resolveAccess({
      user: {
        isLoggedIn,
        hasAccess,
        accessFeatures,
        primarySite,
      },
      requestedAccess: {
        ...requested,
      },
      goToLogin: goToLoginPage,
    });

    const emptyPromise = new Promise<AccessResolverResponse>(() => {
      /** We don't want to resolve promise because of redirect */
    });

    return resolverResponse || emptyPromise;
  } catch (error) {
    const errorText = 'Unexpected error when resolving access';
    log.error(errorText, error);
    return {
      error: new Error(errorText, { cause: error }),
    };
  }
};
