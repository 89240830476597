<script lang="ts">
  import { fade } from 'svelte/transition';
  import { onMount } from 'svelte';

  let init = false;
  let delay = 1000;
  let duration = 1000;

  onMount(() => {
    init = true;
  });
</script>

{#if init}
  <div in:fade={{ duration, delay }} class="wrapper">
    <div class="spinner"></div>
    <slot />
  </div>
{/if}

<style>
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  .spinner {
    margin: 15px auto;
    position: relative;
    border: 5px solid rgba(0, 0, 0, 0.15);
    border-left: 5px solid #888;
    transform: translateZ(0);
    animation: spin 1.1s infinite linear;
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
