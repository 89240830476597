export type WebResponseError = {
  error: Error;
};

const isError = (response: unknown): response is WebResponseError =>
  !!(response as WebResponseError)?.error;

export default {
  isError,
};
