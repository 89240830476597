import type { Variant } from 'unleash-client/lib/variant';

type UnleashVariantJSON = {
  data: Variant;
};

// Gets variant of unleash test feature. The variants must be defined in unleash.
export async function getTestVariant(unleashTestId: string): Promise<Variant> {
  const varianturl = `${window.location.origin}/api/broiler/variant/${unleashTestId}`;
  const response = await fetch(varianturl);
  const { data } = (await response.json()) as UnleashVariantJSON;
  return data;
}
