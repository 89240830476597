<script lang="ts">
  export let title = 'Noe gikk galt, prøv å laste siden på nytt';
  export let text: string;
</script>

<div>
  <h1>Feil</h1>
  <h2>{title}</h2>
  <p>{text}</p>
</div>

<style>
  div {
    width: 100%;
    padding: 40px;
    box-sizing: border-box;
  }
</style>
