import { type UserAccess } from '../src/accessStatusResolver';

enum BaseStates {
  loggedOut = 'loggedOut',
  hasAccess = 'hasAccess',
}

enum RequestingPremium {
  hasPlussAlt = 'hasPlussAlt',
  hasNonPlussAlt = 'hasNonPlussAlt',
  noSubscription = 'noSubscription',
  noSubscriptionHasPrimarySite = 'noSubscriptionHasPrimarySite',
  familySubscription = 'familySubscription',
  defaultAffiliation = 'defaultAffiliation',
}

export const TestStates = { ...BaseStates, ...RequestingPremium };
export type TestStates = typeof TestStates;

export enum AccessFeatures {
  pluss = 'pluss',
  plussalt = 'plussalt',
  sport_promo = 'sport_promo',
  sport_premium = 'sport_premium',
}

const plussaltAccessFeatures = [
  AccessFeatures.pluss,
  AccessFeatures.plussalt,
  AccessFeatures.sport_promo,
  AccessFeatures.sport_premium,
];

export const testStates: Record<string, UserAccess> = {
  [TestStates.loggedOut]: {
    isLoggedIn: false,
    hasAccess: false,
    accessFeatures: [],
    primarySite: null,
  },
  [TestStates.hasAccess]: {
    isLoggedIn: true,
    hasAccess: true,
    accessFeatures: [AccessFeatures.pluss],
    primarySite: null,
  },
  [TestStates.hasPlussAlt]: {
    isLoggedIn: true,
    hasAccess: true,
    accessFeatures: plussaltAccessFeatures,
    primarySite: null,
  },
  [TestStates.hasNonPlussAlt]: {
    isLoggedIn: true,
    hasAccess: false,
    accessFeatures: [AccessFeatures.pluss, AccessFeatures.sport_promo],
    primarySite: {
      domain: 'www.nettavisen.no',
      name: 'Nettavisen',
      affiliations: ['subscription'],
    },
  },
  [TestStates.noSubscription]: {
    isLoggedIn: true,
    hasAccess: false,
    accessFeatures: [],
  },
  [TestStates.noSubscriptionHasPrimarySite]: {
    isLoggedIn: true,
    hasAccess: false,
    accessFeatures: [],
    primarySite: {
      domain: 'www.sb.no',
      name: 'Sandefjords Blad',
    },
  },
  [TestStates.familySubscription]: {
    isLoggedIn: true,
    hasAccess: false,
    accessFeatures: [AccessFeatures.pluss, AccessFeatures.sport_promo],
    primarySite: {
      domain: 'www.nettavisen.no',
      name: 'Nettavisen',
      affiliations: ['family', 'access'],
    },
  },
  [TestStates.defaultAffiliation]: {
    isLoggedIn: true,
    hasAccess: false,
    accessFeatures: [AccessFeatures.pluss],
    primarySite: {
      domain: 'www.nettavisen.no',
      name: 'Nettavisen',
      affiliations: ['default'],
    },
  },
};
