import { log } from './logging/logger';

export type Offering = {
  product: {
    publication_domain: string;
  };
};

type OfferingsResponse = {
  offerings: Array<Offering>;
};

export type PublicationAccess = Array<{
  publicationDomain: string;
  accessFeatures: string[];
}>;

export const fetchOfferings = async (
  publicationDomain: string,
  publicationAccess: PublicationAccess,
  channel: string
): Promise<OfferingsResponse> => {
  try {
    const url = `${window.location.origin}/api/broiler/offerings`;

    const fetchOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        publicationDomain,
        publicationAccess,
        channel,
      }),
    };

    const response = await fetch(url, fetchOptions);
    if (!response.ok) {
      log.error('Failed to fetch offerings', response);
      throw new Error(response.statusText);
    }

    const data = await response.json();
    return data as OfferingsResponse;
  } catch (error) {
    log.error('fetchOfferings failed', error);
    throw error;
  }
};
