import { testStates } from '../playwright/testStates';

import {
  getRequested,
  resolveAccess,
  type AccessResolverResponse,
} from './accessStatusResolver';

const { searchParams } = new URL(document.location.href);
const testState =
  searchParams.get('testState') || 'hasNonPlussAltRequestsPremium';
const requested = getRequested();

export const mockUnlock = async (): Promise<AccessResolverResponse> => {
  if (requested.error) {
    return {
      error: requested.error,
    };
  }

  const resolverResponse = await resolveAccess({
    user: { ...testStates[testState] },
    requestedAccess: { ...requested },
    goToLogin: () => {
      window.location.href = loginRoute();
    },
  });

  const emptyPromise = new Promise<AccessResolverResponse>(() => {
    /** We don't want to resolve promise because of redirect */
  });

  return resolverResponse || emptyPromise;
};

const aidUrl = () => {
  const host = window.location.host;

  if (host.endsWith('.api.no')) {
    const urlParts = host.split('.');
    const env = urlParts[urlParts.length - 3];

    return env === 'localhost'
      ? 'https://www.aid.no.localhost.api.no'
      : `https://www-${env}.aid.no`;
  }
  return 'https://www.aid.no';
};

const loginRoute = () => {
  const url = new URL(`${aidUrl()}/aid/logg_inn`);
  const requestedUrl = new URLSearchParams(window.location.search).get(
    'requestedUrl'
  );
  url.searchParams.set('requestedUrl', requestedUrl || '');

  return url.href;
};
