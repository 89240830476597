export const createUrl = (urlString: string) => {
  try {
    return new URL(urlString);
  } catch (e) {
    console.error(`${e}, invalid: ${urlString}`);
  }
};

const apiDomainRegex = /\.(snap[0-6]|test|auto)\.api\.no$/;

export const getSiteDomain = (host: string) => {
  const match = apiDomainRegex.exec(host);
  return (match && host.replace(match[0], '')) || host;
};

export const getSiteEnv = (host: string) => {
  const match = apiDomainRegex.exec(host);
  return match ? match[0] : '';
};
