import { getPurchaseUrl, Source } from './getPurchaseUrl';
import { createUrl } from './utils/url';
import type { WebResponseError } from './utils/webresponse';

/*
 * Several parameters are required when resolving access
 * 1)  requestedUrl: The url of the content a user is trying to access
 * 2)  access_features: Which access features does the content require
 * 3)  site_domain: The domain where we check for a users access features.
 *     To gain access, a users access_features needs to contain the requested ones
 */

const getRequiredParams = () => {
  const { searchParams } = new URL(document.location.href);

  const paramNames = {
    requestedUrl: 'requestedUrl',
    accessFeatures: 'access_features',
    siteDomain: 'site_domain',
  };

  const requiredParams: Record<
    string,
    { value: string | null; paramName: string }
  > = {
    requestedUrl: {
      value: searchParams.get(paramNames.requestedUrl),
      paramName: paramNames.requestedUrl,
    },
    accessFeatures: {
      value: searchParams.get(paramNames.accessFeatures),
      paramName: paramNames.accessFeatures,
    },
    siteDomain: {
      value: searchParams.get(paramNames.siteDomain),
      paramName: paramNames.siteDomain,
    },
  };

  const missingParams = Object.keys(requiredParams).reduce(
    (accumulator, key) => {
      if (!requiredParams[key].value) {
        accumulator.push(requiredParams[key].paramName);
      }
      return accumulator;
    },
    [] as Array<string>
  );

  return {
    requestedUrl: requiredParams.requestedUrl.value as string,
    accessFeatures: requiredParams.accessFeatures.value as string,
    siteDomain: requiredParams.siteDomain.value as string,
    missingParams,
  };
};

export const getRequested = (): RequestedAccess => {
  const params = getRequiredParams();

  const error =
    params.missingParams.length > 0 &&
    new Error(params.missingParams.join(', '));

  return {
    url: params.requestedUrl ? createUrl(params.requestedUrl) : undefined,
    accessFeatures: (params.accessFeatures || 'pluss').split(','),
    domain: params.siteDomain,
    ...(error && { error }),
  };
};

export type PrimarySite = {
  domain: string;
  name: string;
  affiliations?: Array<'access' | 'family' | 'subscription' | 'default'>;
};

export type UserAccess = {
  isLoggedIn: boolean;
  hasAccess: boolean;
  accessFeatures: Array<string>;
  primarySite?: PrimarySite | null;
};

export type RequestedAccess = {
  url?: URL;
  domain: string;
  accessFeatures: Array<string>;
  error?: Error;
};

export type AccessResolverData = {
  user: UserAccess;
  requestedAccess: RequestedAccess;
  missingAccessFeatures: Array<string>;
  purchaseUrlData: {
    url: URL;
    source: Source;
  };
};

export type AccessResolverResponse = AccessResolverData | WebResponseError;

type Props = {
  user: UserAccess;
  requestedAccess: RequestedAccess;
  goToLogin: () => void;
};

// returns undefined on codepaths that leads to redirects
export const resolveAccess = async (
  props: Props
): Promise<AccessResolverData | undefined> => {
  const { user, requestedAccess, goToLogin } = props;
  const missingAccessFeatures = requestedAccess.accessFeatures.filter(
    (af) => !user.accessFeatures?.includes(af)
  );

  if (!user.isLoggedIn) {
    goToLogin();
    return;
  }

  const purchaseUrlData =
    user.hasAccess && requestedAccess.url
      ? { url: requestedAccess.url, source: Source.hasAccess }
      : await getPurchaseUrl(
          user,
          missingAccessFeatures,
          requestedAccess.domain,
          requestedAccess.url
        );

  if (globalThis.appProps.isDebug) {
    return {
      user,
      requestedAccess,
      missingAccessFeatures,
      purchaseUrlData: purchaseUrlData,
    };
  }

  window.location.href = purchaseUrlData.url.href;
  return;
};
