import type { FetcherError } from '@amedia/node-clients-abo';

const isFetcherError = (e: unknown): e is FetcherError =>
  (e as FetcherError)?.type !== undefined;

export const isHttpError = (
  e: unknown
): e is FetcherError & { type: 'HTTP'; status: number } =>
  isFetcherError(e) && e.type === 'HTTP' && typeof e?.status === 'number';

export const isNetworkError = (error: unknown): error is TypeError => {
  const networkErrors = [
    'Failed to fetch',
    'NetworkError when attempting to fetch resource.',
    'Network request failed',
    'Load failed',
  ];

  return (
    error instanceof TypeError &&
    networkErrors.some((n) => error.message.startsWith(n))
  );
};
