<script lang="ts">
  import { TestStates } from '../../../playwright/testStates';

  const mockStates = Object.values(TestStates);

  const setUrl = (testState: keyof typeof TestStates) => {
    const url = new URL(window.location.href);
    url.searchParams.set('testState', testState);
    url.searchParams.set('requestedUrl', 'https://www.google.com');
    url.searchParams.set('access_features', 'sport_premium');
    url.searchParams.set('site_domain', 'www.direktesport.no');
    window.location.href = url.href;
  };
</script>

<div>
  {#each mockStates as state}
    <button on:click={() => setUrl(state)}>{state}</button>
  {/each}
</div>
