import pinoLogger, { type LogEvent } from 'pino';

import eik from '../../eik.json';

import { getCircularReplacer } from './utils';
import { isHttpError, isNetworkError } from './fetchError';

const noop = () => {};

const fetchFn = (logEvent: LogEvent) => {
  return fetch('/api/broiler/log', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(logEvent),
  });
};

const shouldIgnoreLogEvent = (logEvent: LogEvent) =>
  [/^avbrutt/, /^cancelled/].some((regex) =>
    regex.test(logEvent.messages[1] || '')
  );

const logger = pinoLogger({
  browser: {
    // Need noop to avoid printing to browser console
    write: noop,
    transmit: {
      send: (_level, logEvent: LogEvent) => {
        if (shouldIgnoreLogEvent(logEvent)) {
          return;
        }
        logEvent.bindings = [
          ...logEvent.bindings,
          {
            'broiler.page': location.href,
          },
        ];
        fetchFn(logEvent).catch(() => ({}));
      },
    },
  },
});

const clientLogger = (error?: Error | string | unknown) =>
  logger.child({
    'broiler.client_version': eik.version,
    'broiler.initial_client_load': new Date().toISOString(),
    'broiler.user_agent': navigator.userAgent,
    'broiler.initial_page': location.href,
    ...(!!error && {
      'broiler.error':
        error instanceof Error
          ? JSON.stringify(error, Object.getOwnPropertyNames(error))
          : JSON.stringify(error, getCircularReplacer()),
    }),
  });

const filteredLogger = (
  e: Error | unknown,
  doLog: (extraInfo?: string) => void
) => {
  if (isNetworkError(e)) {
    return;
  }

  if (isHttpError(e)) {
    if (e.status >= 400 && e.status < 500) {
      clientLogger(e).warn(
        `Fetch failed with client error. Status: ${e.status}`,
        { cause: e.message }
      );
      return;
    } else if (e.status >= 500) {
      return doLog(`Status: ${e.status}`);
    }
  }
  return doLog();
};

const buildMsg = (message: string, e: unknown, extraInfo: string) => {
  return e instanceof Error
    ? `${message}: [${e.message}] ${extraInfo}`
    : `${message} ${extraInfo}`;
};

export const log = {
  error: (message: string, error?: Error | unknown) =>
    filteredLogger(error, (extraInfo = '') =>
      clientLogger(error).error(buildMsg(message, error, extraInfo))
    ),
  warn: (message: string, error?: Error | unknown) =>
    filteredLogger(error, (extraInfo = '') =>
      clientLogger(error).warn(buildMsg(message, error, extraInfo))
    ),
  info: (message: string, error?: Error | unknown) =>
    filteredLogger(error, (extraInfo = '') =>
      clientLogger(error).info(buildMsg(message, error, extraInfo))
    ),
};
