import pinoLogger, { type LogEvent } from 'pino';

import eik from '../../eik.json';

const noop = () => {};

const fetchFn = (logEvent: LogEvent) =>
  fetch('/api/broiler/log', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(logEvent),
  });

const shouldIgnoreLogEvent = (logEvent: LogEvent) =>
  [/^avbrutt/, /^cancelled/].some((regex) =>
    regex.test(logEvent.messages[1] || '')
  );

const logger = pinoLogger({
  browser: {
    // Need noop to avoid printing to browser console
    write: noop,
    transmit: {
      send: (_level, logEvent: LogEvent) => {
        if (shouldIgnoreLogEvent(logEvent)) {
          return;
        }
        logEvent.bindings = [
          ...logEvent.bindings,
          {
            'broiler.page': location.href,
          },
        ];
        fetchFn(logEvent).catch(() => ({}));
      },
    },
  },
});

const disabledLogger = pinoLogger({ enabled: false });

const clientLogger = (error?: Error | string | unknown) =>
  typeof process !== 'undefined' && process.env?.STORYBOOK
    ? disabledLogger
    : logger.child({
        'broiler.client_version': eik.version,
        'broiler.initial_client_load': new Date().toISOString(),
        'broiler.user_agent': navigator.userAgent,
        'broiler.initial_page': location.href,
        ...(!!error && { 'broiler.error': JSON.stringify(error) }),
      });

export const log = {
  error: (message: string, error?: Error | string | unknown) =>
    clientLogger(error).error(message),
  warn: (message: string, error?: Error | string | unknown) =>
    clientLogger(error).warn(message),
  info: (message: string, error?: Error | string | unknown) =>
    clientLogger(error).info(message),
};
